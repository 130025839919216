@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .input-control {
        @apply p-2 mb-2 border-2 border-gray-100 rounded;
    }

    .form-label {
        @apply block mb-1 font-medium;
    }

    .button {
        @apply p-2 font-medium transition duration-200 ease-in-out rounded;
    }

    .button:disabled {
        @apply text-gray-300 bg-gray-200 cursor-not-allowed;
    }

    .table-striped tr:nth-child(even) {
        @apply bg-gray-100;
    }

    .table tbody tr:hover {
        @apply hover:bg-blue-50;
    }

    .table td, .table th {
        @apply p-2;
    }
}